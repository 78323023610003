
import { gql } from "graphql-tag";
import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

const PAYMENT_ICONS_QUERY = gql`
  query payment_icon($uid: String!, $lang: String!) {
    payment_icon(uid: $uid, lang: $lang) {
      icon_list {
        icon_name
        icon_link {
          ...documentLink
          ...externalLink
        }
      }
    }
  }
  ${documentLink}
  ${externalLink}
`;

export default {
  props: {
    isCartPage: { type: Boolean, default: false },
    isFooter: { type: Boolean, default: false },
  },

  data() {
    return {
      paymentIcons: [
        {
          src: "visa-logo.png",
          alt: "Visa logo",
          width: "40",
          height: "13",
        },
        {
          src: "mastercard-logo.png",
          alt: "Master card logo",
          width: "33",
          height: "20",
        },
        {
          src: "paypal-logo.png",
          alt: "Paypal logo",
          width: "67",
          height: "18",
        },
        {
          src: "american-express-logo.png",
          class: "m-auto",
          alt: "American express logo",
          width: "47",
          height: "13",
        },
        {
          src: "afterpay-logo.png",
          alt: "Afterpay logo",
          width: "71",
          height: "15",
        },
        {
          src: "humm-logo.png",
          alt: "Humm logo",
          width: "69",
          height: "24",
        },
        {
          src: "pay-logo.svg",
          class: "m-auto",
          alt: "Pay logo",
          width: "81",
          height: "13",
        },
      ],
    };
  },

  async fetch() {
    const data = await this.$getPrismicData({
      query: PAYMENT_ICONS_QUERY,
      variables: { uid: "paymenticons" },
    });

    const newPaymentIcons = this.reCreateIcons(data.payment_icon.icon_list);
    if (newPaymentIcons.length) {
      this.paymentIcons = newPaymentIcons;
    }
  },

  fetchOnServer: false,

  methods: {
    reCreateIcons(serverIcons) {
      let paymentIcons = [];
      for (let { icon_name: name, icon_link: link } of serverIcons) {
        let foundIcon = this.paymentIcons.find(({ alt }) => {
          let iconName = name.split(/(card|-)/);
          if (iconName.length) {
            return alt.toLowerCase().startsWith(iconName[0] + " ");
          }
          return false;
        });
        if (foundIcon) {
          paymentIcons.push({ ...foundIcon, link });
        }
      }
      return paymentIcons;
    },
    getImage({ src, alt, width, height }) {
      return {
        src,
        alt,
        width,
        height,
        provider: "ipx",
        class: `${
          this.isCartPage && /(American|Pay)\s/.test(alt) ? "margin-auto" : ""
        } ${this.isFooter ? "margin-mx-auto" : ""}`,
      };
    },
  },
};
