
import { gql } from "graphql-tag";
import { mapState } from "vuex";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

const UID = "site-footer";

export const FOOTER_FRAGMENT = gql`
  fragment footer_site_footer on Footer {
    customer_service_title
    customer_service_links {
      link_label
      link {
        ...documentLink
        ...externalLink
        ... on Contact_us {
          __typename
          _meta {
            uid
            type
          }
        }
      }
    }
    about_title
    about_links {
      link_label
      link {
        ...documentLink
        ...externalLink
      }
    }
    shop_with_us_title
    shop_with_us_links {
      link_label
      link {
        ...documentLink
        ...externalLink
      }
    }
    social_icons_title
    social_icons {
      icon_name
      icon_link {
        ...documentLink
        ...externalLink
      }
    }
    payment_icon_document {
      ...documentLink
    }
    copyright_text
  }
  ${documentLink}
  ${externalLink}
`;

const FOOTER_QUERY = gql`
  query footer($uid: String!, $lang: String!) {
    footer(uid: $uid, lang: $lang) {
      ...footer_site_footer
    }
  }
  ${FOOTER_FRAGMENT}
`;

export default {
  data: () => ({
    footer: null,
    review: null,
    mappingCols: [
      {
        titleKey: "customer_service_title",
        linksKey: "customer_service_links",
      },
      {
        titleKey: "about_title",
        linksKey: "about_links",
      },
      {
        titleKey: "shop_with_us_title",
        linksKey: "shop_with_us_links",
      },
    ],
    reviewImage: {
      src: "https://api.productreview.com.au/api/services/rating-badge/v2/au/dd184ca8-9cab-3495-a8f9-d9bc2f6f2d98/from-internal-entry-id?resolution=hd&theme=light&width=300",
      alt: "Snooze",
      link: "https://www.productreview.com.au/listings/snooze",
    },
  }),

  async fetch() {
    const { footer } = await this.$getPrismicData({
      query: FOOTER_QUERY,
      variables: { uid: UID },
    });

    this.footer = footer;
  },

  fetchOnServer: false,

  computed: {
    ...mapState(["siteConfig"]),
    currentYearHtml() {
      const currentYear = new Date().getFullYear();
      let html = this.footer.copyright_text
        ? this.$prismic.asHtml(this.footer.copyright_text)
        : "";

      return html.replace(/__year__/g, currentYear);
    },
  },
};
