import { render, staticRenderFns } from "./SiteFooter.vue?vue&type=template&id=a0ad5362&scoped=true&"
import script from "./SiteFooter.vue?vue&type=script&lang=js&"
export * from "./SiteFooter.vue?vue&type=script&lang=js&"
import style0 from "./SiteFooter.vue?vue&type=style&index=0&id=a0ad5362&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0ad5362",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsRichText: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/cms/CmsRichText.vue').default,SiteFooterLinks: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/layout/SiteFooterLinks.vue').default,AppExpansionPanel: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppExpansionPanel.vue').default,AppExpansionPanels: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppExpansionPanels.vue').default,CmsLink: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/cms/CmsLink.vue').default,AppImg: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppImg.vue').default,AppExternalLink: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppExternalLink.vue').default,PaymentIcons: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/PaymentIcons.vue').default,AppContainer: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppContainer.vue').default})
