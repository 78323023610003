
export default {
  props: {
    open: { type: Boolean, default: false },
    itemIndex: { type: Number, required: true },
    accordionItemClasses: { type: String, default: "uppercase py-8" },
  },
  data() {
    return { active: this.open };
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      if (to.name !== from.name) {
        this.active = false;
      }
    },
    open: {
      immediate: true,
      handler(val) {
        this.active = val;
      },
    },
  },
  methods: {
    handleClick() {
      this.active = !this.active;
      this.$parent.$emit("item-clicked", this.itemIndex);
      this.$emit("item-opened", this.active);
    },
  },
};
